* {
  font-family: Arial, sans-serif;
}

:root {
  --app-height: 100%;

  --theme-colors-offBlack: #111111;
  --theme-colors-offWhite: #F0F0F0;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

body {
  background-color: var(--theme-colors-offBlack);
  color: var(--theme-colors-offWhite);
}
